<template>
  <div class="change" v-if="!isProcessing">
    <current-card class="change__current" />
    <div class="change__card">
      <p class="change__card__title">新しいカード情報</p>
      <p class="change__card__text">以下に新しいカードの情報を入力してください。</p>
      <change-card class="change__card__form" @set-card-name="setPostParams" @set-card="setCardInfo" />
      <p class="change__card__date">新しいカードでのお支払いは次回支払い日{{ year }}年{{ month }}月{{ day }}日からとなります。</p>
    </div>
    <v-btn class="change__btn" depressed :disabled="!isFilled" @click="updateCard()">お支払いのカードを変更する</v-btn>
  </div>
</template>

<script>
import ChangeCard from '@/components/common/form/card.vue'
import CurrentCard from '@/components/payment/current.vue'

import { stripe } from '@/stripe'

export default {
  name: 'payment_card_change',
  components: { ChangeCard, CurrentCard },
  data () {
    return {
      postParams: {
        // カード名義
        cardName: '',
        // カード番号
        cardNumber: '',
        // カード期限
        cardExpiry: '',
        // カードCVC
        cardCvc: ''
      },
      // 正しいカード情報かどうか
      isCorrect: {
        // 番号
        cardNumber: '',
        // 期限
        cardExpiry: '',
        // CVC
        cardCvc: ''
      }
    }
  },
  mounted () {
    // カード情報がない場合はお支払い情報のステータスが更新中、サブスクリプションが存在しない場合は処理中なのでホームに遷移
    if (!this.card || this.payment.status === 'updatingCard' || !this.subscription) {
      this.$store.commit('setTelop', { show: true, msg: '現在、処理中です。\n時間をおいてから実行してください。', type: 'warning' })
      this.$router.push({ name: 'home' })
      return
    }

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Object} お支払い情報
     */
    payment () {
      return this.$store.getters['payments/payment']
    },
    /**
     * @return {Object} カード情報
     */
    card () {
      return this.$store.getters['cards/card']
    },
    /**
     * @return {Object} サブスクリプション情報
     */
    subscription () {
      return this.$store.getters['subscriptions/subscription']
    },
    /**
     * @return {Date} 次回支払い年月日
     */
    nextPaymentAt () {
      return this.subscription.nextPaymentAt.toDate()
    },
    /**
     * @return {Number} 支払い年
     */
    year () {
      return this.nextPaymentAt.getFullYear()
    },
    /**
     * @return {String} 支払い月
     */
    month () {
      return ('0' + (this.nextPaymentAt.getMonth() + 1)).slice(-2)
    },
    /**
     * @return {String} 支払い日
     */
    day () {
      return ('0' + this.nextPaymentAt.getDate()).slice(-2)
    },
    /**
     * @return {Boolean} カード番号,期限,CVCが入力されているかどうか
     */
    isCorrectCard () {
      // 入力必須項目
      const requiredKey = ['cardNumber', 'cardExpiry', 'cardCvc']
      return requiredKey.every(key => this.isCorrect[key])
    },
    /**
     * @return {Boolean} カード名義が半角ローマ字と半角スペースのみかどうか
     */
    isValidCardName () {
      const regex = /[a-zA-Z ]/g
      const cardName = this.postParams.cardName.match(regex)
      return cardName ? this.postParams.cardName.trim().length !== 0 && cardName.length === this.postParams.cardName.length : false
    },
    /**
     * @return {Boolean} カード名義とカード情報が入力されているかどうか
     */
    isFilled () {
      return this.isValidCardName && this.isCorrectCard
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters['isProcessing']
    }
  },
  methods: {
    /**
     * ポストパラメータを取得
     * @param {String} key 設定するポストパラメータのキー値
     * @param {String} value 設定するポストパラメータのバリュー値
     */
    setPostParams (key, value) {
      this.postParams[key] = value
    },
    /**
     * 入力されたカード情報を取得
     * @param {String} key 設定するポストパラメータのキー値
     * @param {Object} cardInfo dataに設定するカード情報のオブジェクト
     * @param {Boolean} isCorrectCard 有効なカード情報が入力されているかどうか
     */
    setCardInfo (key, cardInfo, isCorrectCard) {
      this.postParams[key] = cardInfo
      this.isCorrect[key] = isCorrectCard
    },
    /**
     * カード情報の変更
     */
    async updateCard () {
      this.$store.commit('setSubmitting', true)

      // トークン情報の取得
      const result = await stripe.createToken(this.postParams.cardNumber, { name: this.postParams.cardName })

      // エラーの有無による処理
      if (result.error) {
        this.errorMsg = 'カード情報に誤りがあります'
        this.$store.commit('setSubmitting', false)
      } else {
        // 支払い情報のステータス更新およびトークンの作成
        // 作成したtokenをトリガーにCloud Functionsが呼ばれカード情報を作成する
        await Promise.all([
          this.$store.dispatch('payments/updatePayment', { uid: this.uid, params: { status: 'updatingCard', updatedAt: new Date() } }),
          this.$store.dispatch('tokens/createToken', { uid: this.uid, tokenID: result.token.id })
        ])

        // カード情報の監視
        // Cloud Functions側でcardsが作成されるのを待つ
        await this.$store.dispatch('cards/onNewCard', this.uid)

        // カード情報監視のリスナーをデタッチ
        this.$store.dispatch('cards/unsubscribeOnNewCard')

        if (this.subscription.status !== 'active') {
          // 支払が失敗している場合、Cloud Functions側でsubscriptionsが作成されるのを待つ
          await this.$store.dispatch('subscriptions/onNewSubscription', this.uid)

          // サブスクリプション情報監視のリスナーをデタッチ
          this.$store.dispatch('subscriptions/unsubscribeOnNewSubscription')
        }

        // 最新の決済ステータスに応じて分岐を行う
        if (this.subscription.status === 'active') {
          // 支払い状況が正常であればホームへ遷移
          this.$store.commit('setTelop', { show: true, msg: 'カード情報の変更を受け付けました', type: 'success' })
          this.$router.push({ name: 'home' })
        } else {
          // 支払が失敗している場合、読み込み画面を解除する
          // 決済失敗テロップはstore/modules/payments/subscriptions.jsのonSubscriptionの方で表示する
          this.$store.commit('setSubmitting', false)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.change {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_height $padding_width;
  margin: $header_height auto 0;
  &__card {
    margin-top: 50px;
    &__title {
      margin: 0;
      font-size: 1.2rem;
      font-weight: bold;
    }
    &__text {
      margin: 0;
      margin-top: 5px;
      font-size: 1.2rem;
    }
    &__form {
      width: 100%;
      margin-top: 20px;
    }
    &__date {
      margin: 10px 0 0;
      font-size: 1rem;
      text-align: right;
    }
  }
  &__btn {
    display: block;
    margin: 30px auto 0;
    font-size: 1.2rem;
    color: $white_color;
    border-radius: 15px;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: 124px;
      height: 35px;
      padding: 0 20px;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $orange_color;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      color: $white_color !important;
      background-color: $orange_lighten_color !important;
    }
  }
}
</style>
